<template>
    <div class="vocationalSkill_page">
        <BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
        <div class="module1">
            <div class="content allHaveCertificates flex">
                <div class="allHaveCertificates_fl">
                    <div class="title">{{ formData.certificateEveryoneName }}</div>
                    <div class="richText" v-html="formData.certificateEveryoneIntroduce"></div>
                    <div class="btn_div display pointer" @click="service">
                        了解更多 >>
                    </div>
                </div>
                <div class="allHaveCertificates_fr">
                    <img :src="formData.certificateEveryoneImgUrl" alt="" />
                </div>
            </div>
        </div>
        <div class="module2">
            <div class="content hotJob">
                <CourseTitle :title="formData.vocationalSkillLevelName"> </CourseTitle>
                <ul>
                    <li class="hotJob_li" v-for="(item, index) in formData.skillRatingVocationalSkillLevels" :key="index">
                        <div class="hotJob_li_name display">
                            {{ item.vocationalSkillLevelName }}
                        </div>
                        <div class="hotJob_li_text">
                            <div class="cont">
                                {{ item.vocationalSkillLevelIntroduce }}
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="btn display pointer" @click="service">
                    点击咨询更多热门工种
                </div>
            </div>
        </div>
        <div class="module3">
            <CourseTitle :title="formData.whyEvaluationOrganizationName" :isWhite="1">
            </CourseTitle>
            <div class="content gradeEvaluation display">
                <div class="list flex-center column" v-for="(
						item, index
					) in formData.skillRatingWhyEvaluationOrganizations" :key="index">
                    <img class="icon_img" :src="item.whyEvaluationOrganizationIconUrl" alt="" />
                    <div class="name">{{ item.whyEvaluationOrganizationName }}</div>
                    <div class="text">
                        {{ item.whyEvaluationOrganizationIntroduce }}
                    </div>
                    <img class="icon_imgs" :src="item.whyEvaluationOrganizationImgUrl" alt="" />
                </div>
            </div>
        </div>
        <div class="module4">
            <CourseTitle :title="formData.understandTwoOrganizationsName">
            </CourseTitle>
            <div class="content mechanism Between">
                <div class="list flex" v-for="(
						item, index
					) in formData.skillRatingUnderstandTwoOrganizationsS" :key="index">
                    <div class="label display">
                        <div class="name">{{ item.understandTwoOrganizationsName }}</div>
                    </div>
                    <div class="mechanism_content">
                        <p>{{ item.understandTwoOrganizationsIntroduce }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="module5">
            <CourseTitle :title="formData.declarationConditionsName"> </CourseTitle>
            <div class="content acceptanceRequirments">
                <div class="acceptanceRequirments_div flex-center">
                    <div class="fl flex-center column">
                        <div class="name">全国性用人单位</div>
                        <div class="line"></div>
                    </div>
                    <div class="fr flex flex-wrap">
                        <div class="list flex" v-for="(item, index) in skillRatingDeclarationConditionsSLTop" :key="index">
                            <div class="num display">0{{ index + 1 }}</div>
                            <p>{{ item.declarationConditionsIntroduce }}</p>
                        </div>
                    </div>
                </div>
                <div class="acceptanceRequirments_div_two flex-center">
                    <div class="fr flex flex-wrap">
                        <div class="list flex" v-for="(item, index) in skillRatingDeclarationConditionsSLBtootm"
                            :key="index">
                            <div class="num display">0{{ index + 1 }}</div>
                            <p>{{ item.declarationConditionsIntroduce }}</p>
                        </div>
                    </div>
                    <div class="fl flex-center column">
                        <div class="name">社会评价组织</div>
                        <div class="line"></div>
                    </div>
                </div>
                <div class="btn display pointer" @click="service">
                    想知道是否符合申报条件？
                </div>
            </div>
        </div>
        <div class="module6">
            <CourseTitle :title="formData.applicationMaterialsName"> </CourseTitle>
            <div class="content applicationMaterials Between">
                <div class="list_div">
                    <div class="title display">全国性用人单位</div>
                    <div class="contBox">
                        <ul>
                            <li class="felx-center flex" v-for="(item, index) in applicationMaterialsFl" :key="index">
                                <div class="num display">0{{ index + 1 }}</div>
                                <div class="text">
                                    {{ item.applicationMaterialsIntroduce }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="list_div">
                    <div class="title display">社会评价组织</div>
                    <div class="contBox">
                        <ul>
                            <li class="felx-center flex" v-for="(item, index) in applicationMaterialsFr" :key="index">
                                <div class="num display">0{{ index + 1 }}</div>
                                <div class="text">
                                    {{ item.applicationMaterialsIntroduce }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="btn display pointer" @click="service">材料模板一键下载</div>
        </div>
        <div class="module7">
            <CourseTitle :title="formData.butlerServiceName"> </CourseTitle>
            <div class="content butlerService">
                <ul class="display">
                    <li class="flex column" v-for="(item, index) in formData.skillRatingButlerServices" :key="index">
                        <img :src="item.butlerServiceImgUrl" alt="" />
                        <div class="name">{{ item.butlerServiceName }}</div>
                        <div class="num">0{{ index + 1 }}</div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="module8">
            <CourseTitle :title="formData.freeCustomizationProgramName">
            </CourseTitle>
            <FreeCharge :status="11" :type="'职业技能等级评价机构申报'"></FreeCharge>
        </div>
    </div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from '../components/courseTitle.vue'
import FreeCharge from '@/components/freeCharge'
export default {
    components: {
        BannerImg,
        CourseTitle,
        FreeCharge,
    },
    data() {
        return {
            formData: {},
            skillRatingDeclarationConditionsSLTop: [],
            skillRatingDeclarationConditionsSLBtootm: [],
            applicationMaterialsFl: [],
            applicationMaterialsFr: [],
        }
    },
    mounted() {
        this.init()
        document.documentElement.scrollTop = 0
    },
    methods: {
        //初始化数据
        init() {
            this.api.vocationalSkillApi().then((res) => {
                if (res.data.skillRatingDeclarationConditionsS.length != 0) {
                    res.data.skillRatingDeclarationConditionsS.forEach((item) => {
                        if (item.type == 1) {
                            this.skillRatingDeclarationConditionsSLTop.push(item)
                        } else {
                            this.skillRatingDeclarationConditionsSLBtootm.push(item)
                        }
                    })
                }
                res.data.skillRatingApplicationMaterialsS.forEach((item) => {
                    if (item.type == 1) {
                        this.applicationMaterialsFl.push(item)
                    } else {
                        this.applicationMaterialsFr.push(item)
                    }
                })
                this.formData = res.data
            })
        },
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.vocationalSkill_page {
    .btn {
        width: 428px;
        height: 48px;
        background: #ff881e;
        border-radius: 5px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin: 0 auto;
    }

    .module1 {
        height: 815px;
        background: url('./img/vocationalSkill/bg1.png') no-repeat;
        background-size: 100% 100%;

        .allHaveCertificates {
            padding-top: 145px;

            .allHaveCertificates_fl {
                margin-top: 56px;

                .title {
                    font-size: 32px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 24px;
                }

                .richText {
                    width: 483px;
                    height: 160px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 24px;
                    margin: 30px 0;
                }

                .btn_div {
                    width: 178px;
                    height: 48px;
                    background: #ff881e;
                    border-radius: 5px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #ffffff;
                    margin: none;
                }
            }

            .allHaveCertificates_fr {
                margin-left: 92px;

                img {
                    width: 520px;
                    height: 570px;
                }
            }
        }
    }

    .module2 {
        background: white;

        .hotJob {
            height: 970px;
            background: url('./img/vocationalSkill/bg2.png') no-repeat;
            background-size: 100% 100%;

            ul {
                position: relative;

                .hotJob_li:nth-child(1) {
                    .hotJob_li_name {
                        top: 100px;
                        left: 420px;
                    }

                    .hotJob_li_text {
                        top: 60px;
                        left: 108px;
                    }
                }

                .hotJob_li:nth-child(2) {
                    .hotJob_li_name {
                        top: 100px;
                        right: 420px;
                    }

                    .hotJob_li_text {
                        top: 60px;
                        right: 108px;
                    }
                }

                .hotJob_li:nth-child(3) {
                    .hotJob_li_name {
                        top: 300px;
                        left: 310px;
                    }

                    .hotJob_li_text {
                        top: 260px;
                        left: 0px;
                    }
                }

                .hotJob_li:nth-child(4) {
                    .hotJob_li_name {
                        top: 300px;
                        right: 310px;
                    }

                    .hotJob_li_text {
                        top: 260px;
                        right: 0px;
                    }
                }

                .hotJob_li:nth-child(5) {
                    .hotJob_li_name {
                        top: 480px;
                        left: 410px;
                    }

                    .hotJob_li_text {
                        top: 480px;
                        left: 100px;
                    }
                }

                .hotJob_li:nth-child(6) {
                    .hotJob_li_name {
                        top: 480px;
                        right: 410px;
                    }

                    .hotJob_li_text {
                        top: 480px;
                        right: 100px;
                    }
                }

                .hotJob_li {
                    .hotJob_li_name {
                        width: 118px;
                        height: 118px;
                        background: #003c7e;
                        font-size: 26px;
                        font-family: HarmonyOS Sans SC;
                        font-weight: bold;
                        color: #ffffff;
                        line-height: 26px;
                        position: absolute;
                        border-radius: 50%;
                        text-align: center;
                    }

                    .hotJob_li_text {
                        width: 277px;
                        height: 102px;
                        background: #eaeaea;
                        border-radius: 5px;
                        position: absolute;
                        padding: 20px 24px;
                        box-sizing: border-box;

                        .cont {
                            width: 224px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            line-height: 20px;
                        }
                    }
                }
            }

            .btn {
                margin-top: 690px;
            }
        }
    }

    .module3 {
        height: 736px;
        background: url('./img/vocationalSkill/bg3.png') no-repeat;
        background-size: 100% 100%;

        .gradeEvaluation {
            margin-top: 41px;

            .list:nth-child(1) {
                margin-left: 0 !important;
            }

            .list {
                width: 280px;
                margin-left: 25px;

                .icon_img {
                    width: 100px;
                    height: 100px;
                }

                .name {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #ffffff;
                    line-height: 24px;
                    margin: 22px 0;
                }

                .text {
                    width: 225px;
                    height: 82px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 22px;
                }

                .icon_imgs {
                    width: 280px;
                    height: 162px;
                    margin-top: 22px;
                }
            }
        }
    }

    .module4 {
        height: 416px;

        .mechanism {
            width: 1200px;
            margin-top: 51px;

            .list:last-child {
                .label {
                    background: #ff881e !important;
                }

                .mechanism_content {
                    border: 1px solid #ff881e !important;
                }
            }

            .list {
                .label {
                    width: 180px;
                    height: 98px;
                    background: #003c7e;
                    border-radius: 0px 20px 0px 10px;
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #ffffff;
                    line-height: 28px;
                    margin-top: 10px;

                    .name {
                        width: 96px;
                    }
                }

                .mechanism_content {
                    width: 351px;
                    height: 132px;
                    border: 1px solid #003c7e;
                    border-radius: 5px;
                    margin-left: 10px;
                    padding: 40px;
                    box-sizing: border-box;

                    p {
                        width: 252px;
                        height: 42px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                    }
                }
            }
        }
    }

    .module5 {
        height: 1030px;
        background: url('./img/vocationalSkill/bg4.png') no-repeat;
        background-size: 100% 100%;

        .acceptanceRequirments {
            margin-top: 31px;

            .acceptanceRequirments_div {
                width: 1200px;
                height: 282px;
                background: #003c7e;

                .fl {
                    width: 217px;
                    height: 100%;

                    .name {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #ffffff;
                        margin-top: 95px;
                    }

                    .line {
                        width: 29px;
                        height: 4px;
                        background: #f4f5f5;
                        border-radius: 2px;
                        margin: 0 auto;
                        margin-top: 19px;
                    }
                }

                .fr {
                    width: 980px;
                    height: 274px;
                    background: #fff;
                    margin-top: 1px;
                    padding: 46px 48px;
                    box-sizing: border-box;

                    .list:nth-child(3n + 1) {
                        margin-left: 0 !important;
                    }

                    .list {
                        margin-left: 54px;
                        margin-bottom: 54px;

                        .num {
                            width: 55px;
                            height: 38px;
                            background: url('./img/vocationalSkill/bg5.png') no-repeat;
                            background-size: 100% 100%;
                            padding-left: 6px;
                            font-size: 24px;
                            font-family: Impact;
                            font-weight: 400;
                            color: #ffffff;
                        }

                        p {
                            width: 192px;
                            height: 66px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            line-height: 24px;
                            margin-left: 10px;
                            margin-top: -6px;
                        }
                    }
                }
            }

            .acceptanceRequirments_div_two {
                width: 1200px;
                height: 350px;
                background: #003c7e;
                margin-top: 32px;
                padding-left: 4px;

                .fl {
                    width: 217px;
                    height: 100%;

                    .name {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #ffffff;
                        margin-top: 95px;
                    }

                    .line {
                        width: 29px;
                        height: 4px;
                        background: #f4f5f5;
                        border-radius: 2px;
                        margin: 0 auto;
                        margin-top: 19px;
                    }
                }

                .fr {
                    width: 980px;
                    height: 344px;
                    background: #fff;
                    margin-top: -1px;
                    padding: 46px 48px;
                    box-sizing: border-box;

                    .list:nth-child(1) {
                        margin-left: 0 !important;

                        p {
                            height: 208px;
                        }
                    }

                    .list:nth-child(2) {
                        p {
                            height: 160px;
                        }
                    }

                    .list:nth-child(3) {
                        height: 160px;

                        p {
                            height: 88px;
                        }
                    }

                    .list:nth-child(4) {
                        margin-left: 306px;

                        p {
                            height: 88px;
                        }
                    }

                    .list {
                        margin-left: 38px;

                        .num {
                            width: 55px;
                            height: 38px;
                            background: url('./img/vocationalSkill/bg5.png') no-repeat;
                            background-size: 100% 100%;
                            padding-left: 6px;
                            font-size: 24px;
                            font-family: Impact;
                            font-weight: 400;
                            color: #ffffff;
                        }

                        p {
                            width: 204px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            line-height: 24px;
                            margin-top: -6px;
                            margin-left: 10px;
                        }
                    }
                }
            }

            .btn {
                margin-top: 62px;
            }
        }
    }

    .module6 {
        height: 870px;

        .applicationMaterials {
            margin-top: 41px;

            .list_div {
                width: 580px;
                height: 520px;
                background: #ffffff;
                box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
                border-radius: 5px;

                .title {
                    width: 580px;
                    height: 60px;
                    background: #003c7e;
                    border-radius: 5px 5px 0 0px;
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #ffffff;
                }

                .contBox {
                    padding: 34px 19px 34px 30px;

                    ul {
                        li {
                            margin-bottom: 20px;

                            .num {
                                width: 34px;
                                height: 28px;
                                background: #003c7e;
                                border-radius: 5px;
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #ffffff;
                            }

                            .text {
                                width: 485px;
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                                line-height: 22px;
                                margin-top: 2px;
                                margin-left: 12px;
                            }
                        }
                    }
                }
            }
        }

        .btn {
            margin-top: 50px;
        }
    }

    .module7 {
        height: 370px;
        background: #fafafa;

        .butlerService {
            margin-top: 40px;

            ul {
                li:nth-child(1) {
                    margin-left: 0 !important;
                }

                li {
                    position: relative;
                    margin-left: 122px;

                    img {
                        width: 60px;
                        height: 60px;
                    }

                    .name {
                        font-size: 22px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        margin-top: 26px;
                    }

                    .num {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #104784;
                        line-height: 24px;
                        opacity: 0.3;
                        position: absolute;
                        top: 20px;
                        right: -56px;
                    }
                }
            }
        }
    }
}</style>